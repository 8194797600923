import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { Channel, SubscribedChannel } from 'src/app/core/models/channel';
import { CommonModule } from '@angular/common';
import { RecipientChannelItemComponent } from '../../components/recipient-channel-item/recipient-channel-item.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ApiService } from 'src/app/core/services/api.service';
import { FsLogoBannerComponent } from '../../components/fs-logo-banner/fs-logo-banner.component';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
  standalone: true,
  imports: [CommonModule, RecipientChannelItemComponent, FsLogoBannerComponent],
})
export class RecommendedComponent implements OnInit {
  private user: User | null;
  tierTwoChannels: Channel[];
  subscribedTierTwoChannels: SubscribedChannel[];
  rendered: boolean = false;

  constructor(
    private apiService: ApiService,
    private loader: LoaderService,
    private toast: ToastNotificationService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
        this.getTierTwoChannels();
      }
    });
  }

  // prevent refetch on init
  ionViewWillLeave() {
    this.rendered = true;
    this.loader.dismiss();
  }

  ionViewDidEnter() {
    if (this.rendered) {
      this.getTierTwoChannels();
    }
  }

  getTierTwoChannels: Function = () => {
    this.loader.presentControlPanelLoader();

    this.apiService.getReceiverChannels(this.user._id, 'TWO').subscribe(
      (res) => {
        this.tierTwoChannels = res.results['TWO'];
        this.loader.dismiss();
      },

      (error) => {
        if (error !== 401) {
          this.toast.presentToastError(
            'An error has occured, please try again later.'
          );
          this.loader.dismiss();
        }
      }
    );
  };

  channelSubscription: Function = (channel: Channel) => {
    if (!channel.channels.isSubscribed) {
      this.apiService
        .postReceiverChannels(this.user._id, channel._id)
        .subscribe(
          (res) => {
            this.toast.presentToastSuccess(
              `Successfully subscribed to ${channel.channelId.name}.`
            );
          },

          (error) => {
            channel.channels.isSubscribed = false;
            this.toast.presentToastError(
              `Unable to subscribed to ${channel.channelId.name}.`
            );
          }
        );
    } else {
      this.apiService.deleteReceiverChannels(channel._id).subscribe(
        (res) => {
          this.toast.presentToastSuccess(
            `Successfully unsubscribed to ${channel.channelId.name}.`
          );
        },

        (error) => {
          channel.channels.isSubscribed = true;
          this.toast.presentToastError(
            `Unable to unsubscribe from ${channel.channelId.name}.`
          );
        }
      );
    }
  };
}
