import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor(private toastController: ToastController) { }
  
  async presentToastSuccess(mes: string) {
    const toast = await this.toastController.create({
      message: mes,
      cssClass: 'my-toast-css',
      duration: 2000,
      translucent: true,
      position: 'bottom',
      buttons: [
        {
          side: 'end',
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
  
  async presentToastError(mes: string) {
    const toast = await this.toastController.create({
      message: mes,
      cssClass: 'my-error-toast-css',
      duration: 2000,
      translucent: true,
      position: 'bottom',
      buttons: [
        {
          side: 'end',
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
  
  
}
