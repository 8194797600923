import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ConvertTierPipe } from '../shared/pipes/convert-tier.pipe';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoaderService } from './services/loader.service';
import { ApiService } from './services/api.service';
import { PopoverService } from './services/popover-service.service';
import { ToastNotificationService } from './services/toast-notification.service';


@NgModule({
  declarations: [
  ],
  imports: [
    ConvertTierPipe
  ],
  exports: [ConvertTierPipe, HttpErrorInterceptor, LoaderService, ApiService, PopoverService, ToastNotificationService]
  
})

export class CoreModule { 
  
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
