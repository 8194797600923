import { Component, OnInit } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { Router, RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';

import {
  Channel,
  SubscribedChannel,
  ChannelData,
} from 'src/app/core/models/channel';

import { CommonModule } from '@angular/common';
import { RecipientChannelItemComponent } from '../../components/recipient-channel-item/recipient-channel-item.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ApiService } from 'src/app/core/services/api.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, RecipientChannelItemComponent],
})
export class SearchPage implements OnInit {
  private user: User | null;
  private rendered: boolean = false;
  private alerted: boolean = false;
  tierThreeChannels: ChannelData[] = [];
  subscribedTierThreeChannels: Channel[] = [];
  displayChannels: ChannelData[] = [];

  searchTerm: string;

  constructor(
    private alertController: AlertController,
    private toast: ToastNotificationService,
    private apiService: ApiService,
    private router: Router,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
        this.getSearchableChannels();
      } else {
        this.toast.presentToastError('Session expired, please login again.');
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  ionViewWillEnter() {
    if (this.rendered) {
      this.getSearchableChannels();
    }
  }

  ionViewWillLeave() {
    this.rendered = true;
  }

  getSearchableChannels: Function = () => {
    this.loader.presentControlPanelSearchLoader();
    const tierThreeChannels = this.apiService.getTierChannels('THREE');
    const subscribedTierThreeChannels = this.apiService.getReceiverChannels(
      this.user._id,
      'THREE'
    );

    forkJoin([tierThreeChannels, subscribedTierThreeChannels]).subscribe(
      (res) => {
        this.tierThreeChannels = res[0].results as any;
        this.subscribedTierThreeChannels = res[1].results['THREE'];
        
        this.tierThreeChannels = this.tierThreeChannels.filter(channel => {
          return channel._id !== "mvp-demo@mail.fresnostate.edu";
        })

        if (this.subscribedTierThreeChannels) {
          this.tierThreeChannels = this.tierThreeChannels.filter((channel) => {
            return !this.subscribedTierThreeChannels.some(
              (subscribedChannel) => {
                return channel._id === subscribedChannel._id;
              }
            );
          });
        }
  
        this.tierThreeChannels = this.tierThreeChannels.map((channel) => {
          return { ...channel, expand: false };
        });

        this.displayChannels = this.tierThreeChannels;
        this.loader.dismiss();
      },

      (error) => {
        this.loader.dismiss();
      }
    );
  };

  getChannels: Function = (ev: any) => {
    let target = ev.target.value.trim().toLowerCase();
    if (target) {
      this.displayChannels = this.tierThreeChannels.filter((search) => {
        return search.name.toLowerCase().includes(target);
      });
    } else {
      this.displayChannels = this.tierThreeChannels;
    }
  };

  goBack: Function = () => {
    this.router.navigate(['control-panel/discover']);
  };

  subscribeToChannel: Function = (targetChannel: ChannelData) => {
    this.apiService
      .postReceiverChannels(this.user._id, targetChannel._id)
      .subscribe(
        (res) => {
          if (res._id) {
            this.apiService
              .getChannelById(targetChannel._id)
              .subscribe((channelRes) => {
                if (channelRes) {
                  this.tierThreeChannels = this.tierThreeChannels.filter(
                    (channel) => {
                      return channel._id !== targetChannel._id;
                    }
                  );
                  this.displayChannels = this.tierThreeChannels;
                  this.toast.presentToastSuccess(
                    `Successfully subscribed to ${channelRes.name}.`
                  );
                } else {
                  this.toast.presentToastError(
                    'An error has occured, please try again later.'
                  );
                }
              });
          }
        },

        (error) => {
          this.toast.presentToastError(
            'An error has occured, please try again later.'
          );
        }
      );
  };

  presentAlertConfirm: Function = async (channel: ChannelData) => {
    if (!this.alerted) {
      this.alerted = true;
      const alert = await this.alertController.create({
        cssClass: 'my-alert-css-receiver-discover',
        message: `Do you wish to subscribe to <strong>${channel.name}</strong>?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'cancel-btn',
            handler: () => {},
          },
          {
            text: 'Subscribe',
            handler: () => {
              this.subscribeToChannel(channel);
            },
          },
        ],
      });

      await alert.present();
      await alert.onDidDismiss();
      this.alerted = false;
    }
  };
}
