import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTier',
  standalone: true,
})
export class ConvertTierPipe implements PipeTransform {
  transform(tier: string): number | string {
    switch (tier) {
      case 'ONE':
        return 1;

      case 'TWO':
        return 2;

      case 'THREE':
        return 3;

      default:
        return 'No Tier';
    }
  }
}
