import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { PopoverService } from 'src/app/core/services/popover-service.service';
import { ApiService } from 'src/app/core/services/api.service';
import { FsLogoBannerComponent } from '../fs-logo-banner/fs-logo-banner.component';

@Component({
  selector: 'app-recipient-navbar',
  templateUrl: './recipient-navbar.component.html',
  styleUrls: ['./recipient-navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FsLogoBannerComponent],
})
export class RecipientNavbarComponent implements OnInit {
  private user: User | null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private popoverService: PopoverService,
    private toast: ToastNotificationService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
      } else {
        this.toast.presentToastError('Session expired, please login again.');
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  presentPopover: Function = (event: any): void => {
    if (localStorage.getItem('mvp_user')) {
      this.popoverService.presentPopover(event);
    }
  };
}
