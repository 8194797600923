import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Channel } from 'src/app/core/models/channel';
import { FormsModule } from '@angular/forms';
import { ChannelItemFunctionalityComponent } from '../channel-item-functionality/channel-item-functionality.component';
import { ConvertTierPipe } from 'src/app/shared/pipes/convert-tier.pipe';
import { ThrottleClickDirective } from 'src/app/shared/directives/throttle-click.directive';

@Component({
  selector: 'app-recipient-channel-item',
  templateUrl: './recipient-channel-item.component.html',
  styleUrls: ['./recipient-channel-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ThrottleClickDirective,
    ChannelItemFunctionalityComponent,
    ConvertTierPipe,
  ],
})
export class RecipientChannelItemComponent implements OnInit {
  @Input() channel: Channel;
  @Input() functionality: string;

  @Output() receiveToggleEvent: EventEmitter<Channel> = new EventEmitter();
  @Output() receiveSubscribeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() receiveUnsubscribeEvent: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {}

  expandInfo: Function = (event: any, channel: Channel): void => {
    if (![...event.target.classList].includes('toggle-no-event')) {
      event.currentTarget.querySelector('.back-svg').classList.toggle('rotate');
      channel.expand = !channel.expand;
    }
  };

  /* Recommended tab, toggle event */
  onReceiveToggleEvent: Function = (event) => {
    this.channel.channels.isSubscribed = event;
    this.receiveToggleEvent.emit(this.channel);
  };

  /* Discover tab, unsubscribe event */
  onReceiveUnsubscribeEvent: Function = (event) => {
    this.receiveUnsubscribeEvent.emit(event);
  };

  /* Search page, subscribe event */
  onReceiveSubscribeEvent: Function = (event) => {
    this.receiveSubscribeEvent.emit(event);
  };
}
