import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  Router,
  CanActivateChild,
} from '@angular/router';
import { User } from '../models/user';
import { take } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { ToastNotificationService } from '../services/toast-notification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private user: User | null = null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toast: ToastNotificationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let auth: boolean;
    this.apiService.user$.pipe(take(1)).subscribe(
      (user) => {
        this.user = user;
        auth = this.checkAuth(route);
      },

      (err) => {
        this.toast.presentToastError(
          'An error has occurred, please refresh the page and try again.'
        );
        auth = false;
      }
    );

    return auth;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let auth: boolean;
    this.apiService.user$.pipe(take(1)).subscribe(
      (user) => {
        this.user = user;
        auth = this.checkAuth(route);
      },

      (err) => {
        this.toast.presentToastError(
          'An error has occurred, please refresh the page and try again.'
        );
        auth = false;
      }
    );

    return auth;
  }

  checkAuth: Function = (route: Route): boolean => {
    if (this.user && !this.apiService.tokenExpired()) {
      const userRoles = this.user.roles;
      if (route.data['role'] && userRoles.indexOf(route.data['role']) === -1) {
        this.router.navigate(['/authorize-error'], {
          skipLocationChange: true,
          replaceUrl: true,
        });
        return false;
      }

      return true;
    }

    this.router.navigate(['/authorize-error'], {
      skipLocationChange: true,
      replaceUrl: true,
    });
    return false;
  };
}
