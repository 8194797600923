import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecipientRoutingModule } from './recipient-routing.module';
import { ChannelItemFunctionalityComponent } from './components/channel-item-functionality/channel-item-functionality.component';
import { RecipientNavbarComponent } from './components/recipient-navbar/recipient-navbar.component';
import { SearchPage } from './pages/search/search.page';
import { DiscoverComponent } from './pages/discover/discover.component';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { EssentialComponent } from './pages/essential/essential.component';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecipientRoutingModule,
    SearchPage,
    DiscoverComponent,
    RecommendedComponent,
    EssentialComponent,
    RecipientNavbarComponent,
    ChannelItemFunctionalityComponent,
  ],
  exports: [RecipientNavbarComponent],
})
export class RecipientModule {}
