import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecipientPage } from './pages/recipient-page/recipient.page';
import { DiscoverComponent } from './pages/discover/discover.component';
import { EssentialComponent } from './pages/essential/essential.component';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { SearchPage } from './pages/search/search.page';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: RecipientPage,
    canActivateChild: [AuthGuard],
    data: {
      role: 'RECIPIENT',
    },
    children: [
      {
        path: '',
        redirectTo: 'recommended',
        pathMatch: 'full',
      },

      {
        path: 'discover',
        component: DiscoverComponent,
      },

      {
        path: 'essential',
        component: EssentialComponent,
      },

      {
        path: 'recommended',
        component: RecommendedComponent,
      },

      {
        path: 'search',
        component: SearchPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecipientRoutingModule {}
