import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, tap, shareReplay, catchError } from 'rxjs/operators';
import { User, Token, Login, UserResults } from '../models/user';
import { Message } from '../models/message';
import { ChannelResults, CreateReceiverChannelResults } from '../models/result';
import {
  Channel,
  CreateReceiverChannel,
  SubscribedChannel,
  ChannelData,
} from '../models/channel';
import { ToastNotificationService } from './toast-notification.service';

const AUTH_USER = 'mvp_user';
const AUTH_TOKEN = 'mvp_token';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private userSub: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  private userToken: Token = {};
  public user$: Observable<User | null> = this.userSub.asObservable();
  public isLoggedIn$: Observable<boolean>;
  public isLoggedOut$: Observable<boolean>;
  private userId: string;

  private API: string = 'https://mvp-api.dx.fresnostate.edu';

  constructor(
    private http: HttpClient,
    private toast: ToastNotificationService
  ) {
    this.isLoggedIn$ = this.user$.pipe(map((user) => !!user));
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map((loggedIn) => !loggedIn));

    const user = localStorage.getItem(AUTH_USER);
    const token = localStorage.getItem(AUTH_TOKEN);
    if (user) {
      let userInfo = JSON.parse(user);
      this.userSub.next(userInfo);
      this.userId = userInfo._id;
    }
    if (token) {
      this.userToken = JSON.parse(token);
    }
  }

  login = (email: string, otp: string): Observable<Login> => {
    return this.http
      .post<Login>(
        `${this.API}/auth/login`,
        { email, otp },
        {
          headers: this.getAuth(),
          withCredentials: true,
        }
      )
      .pipe(
        tap((login) => {
          this.userSub.next(login.user);
          this.userToken = login.token;
          ApiService.setSession(login);
          this.userId = login.user._id;
        }),
        shareReplay(),
        catchError(this.handleError.bind(this))
      );
  };

  logout = () => {
    this.userLogout(this.userId);
    this.userSub.next(null);
    this.userToken = {};
    ApiService.setSession(null);
  };

  userLogout = (userId: string) => {
    return this.http
      .post<Message>(
        `${this.API}/auth/logout`,
        { email: userId },
        {
          headers: this.getAuth(),
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  };

  tokenExpired = () => {
    if (Object.keys(this.userToken).length === 0) {
      return true;
    }

    const currentDate = new Date();
    const tokenDate = new Date(this.userToken.expiresIn);
    return currentDate.getTime() > tokenDate.getTime();
  };

  getOTP = (email: string): Observable<Message> => {
    return this.http
      .post<Message>(
        `${this.API}/users/generate-otp`,
        { email },
        {
          headers: this.getAuth(),
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  };

  getChannels = (): Observable<
    ChannelResults<Channel[] | SubscribedChannel[]>
  > => {
    return this.http
      .get<ChannelResults<Channel[] | SubscribedChannel[]>>(
        `${this.API}/channels`,
        {
          headers: this.getAuth(),
          // params: this.getParams(query),
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  };

  getChannelById = (email: string): Observable<ChannelData> => {
    return this.http
      .get<ChannelData>(`${this.API}/channels/${email}`, {
        headers: this.getAuth(),
        withCredentials: true,
      })
      .pipe(catchError(this.handleError.bind(this)));
  };

  getReceiverChannels = (
    userID: string,
    filter: string
  ): Observable<ChannelResults<ChannelData[]>> => {
    if (filter === '') {
      return this.http
        .get<ChannelResults<Channel[] | SubscribedChannel[]>>(
          `${this.API}/receiver-channels?userId=${userID}`,
          {
            headers: this.getAuth(),
            withCredentials: true,
          }
        )
        .pipe(catchError(this.handleError.bind(this)));
    } else {
      return this.http
        .get<ChannelResults<Channel[] | SubscribedChannel[]>>(
          `${this.API}/receiver-channels?userId=${userID}&tier=${filter}`,
          {
            headers: this.getAuth(),
            withCredentials: true,
          }
        )
        .pipe(catchError(this.handleError.bind(this)));
    }
  };

  getTierChannels = (
    tierFilter: string
  ): Observable<ChannelResults<Channel[]>> => {
    return this.http
      .get<Channel[]>(`${this.API}/channels?tier=${tierFilter}`, {
        headers: this.getAuth(),
        withCredentials: true,
      })
      .pipe(catchError(this.handleError.bind(this)));
  };

  postReceiverChannels = (
    user: string,
    channel: string
  ): Observable<CreateReceiverChannelResults> => {
    return this.http
      .post<CreateReceiverChannel>(
        `${this.API}/receiver-channels`,
        { userId: user, channelId: channel },
        {
          headers: this.getAuth(),
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  };

  deleteReceiverChannels = (id: string) => {
    return this.http
      .delete(`${this.API}/receiver-channels/${id}`, {
        headers: this.getAuth(),
        withCredentials: true,
      })
      .pipe(catchError(this.handleError.bind(this)));
  };

  refreshToken = (refreshToken: string, userId: string) => {
    return this.http
      .post(
        `${this.API}/auth/refresh-token/`,
        { refreshToken: refreshToken, email: userId },
        {
          headers: this.getAuth(),
          withCredentials: true,
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  };

  /** ----------------------------------------- **/

  private getParams(query = {}): HttpParams {
    return Object.entries(query).reduce(
      (params, [key, value]) => params.set(key, (value as string).toString()),
      new HttpParams()
    );
  }

  // change when httponly cookies
  private getAuth(isJson: boolean = false): HttpHeaders {
    return isJson
      ? new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `${this.userToken.tokenType} ${this.userToken.accessToken}`,
          // cors

          // 'Access-Control-Allow-Origin' : 'false'
        })
      : new HttpHeaders({
          Authorization: `${this.userToken.tokenType} ${this.userToken.accessToken}`,
        });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status === 401) {
        //log out
        //this.logout();
        this.toast.presentToastError(
          'Unauthorized, please refresh and login again.'
        );
      } // TODO Notify
      // if (error.error.message) return throwError(() => error.error);
    }
    // return an observable with a user-facing error message
    return throwError(error.status);
  }

  private static setSession(login: Login | null) {
    console.debug('API: setSession');
    if (login && login.user && login.token) {
      localStorage.setItem(AUTH_USER, JSON.stringify(login.user));
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(login.token));
    } else {
      localStorage.removeItem(AUTH_USER);
      localStorage.removeItem(AUTH_TOKEN);
    }
  }
}
