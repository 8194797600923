import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Channel } from 'src/app/core/models/channel';
import { FormsModule } from '@angular/forms';
import { ThrottleClickDirective } from 'src/app/shared/directives/throttle-click.directive';

@Component({
  selector: 'app-channel-item-functionality',
  templateUrl: './channel-item-functionality.component.html',
  styleUrls: ['./channel-item-functionality.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ThrottleClickDirective],
})
export class ChannelItemFunctionalityComponent implements OnInit {
  @Input() channel: Channel;
  @Input() functionality: string;

  /* toggle functionality */
  private disableToggle: boolean = false;
  @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter();

  /* subscribe / unsubscribe functionality */
  @Output() subscribeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() unsubscribeEvent: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {}

  /* toggle functionality */
  onToggleEvent: Function = () => {
    this.toggleEvent.emit(this.channel.channels.isSubscribed);
    this.disableToggle = true;
    setTimeout(() => {
      this.disableToggle = false;
    }, 1500);
  };

  /* subscribe functionality */
  onSubscribeEvent: Function = (ev) => {
    ev.stopPropagation();
    this.subscribeEvent.emit(true);
  };

  /* unsubscribe functionality */
  onUnsubscribeEvent: Function = (ev) => {
    ev.stopPropagation();
    this.unsubscribeEvent.emit(true);
  };
}
