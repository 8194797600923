import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login-routing.module').then(
        (m) => m.LoginRoutingModule
      ),
  },

  {
    path: 'control-panel',
    canActivate: [AuthGuard],
    data: {
      role: 'RECIPIENT',
    },
    loadChildren: () =>
      import('./features/recipient/recipient.module').then(
        (m) => m.RecipientModule
      ),
  },

  {
    path: 'authorize-error',
    loadChildren: () =>
      import('./features/not-authorized/not-authorized-routing.module').then(
        (m) => m.NotAuthorizedRoutingModule
      ),
  },

  {
    path: '**',
    loadChildren: () =>
      import('./features/not-found/not-found-routing.module').then(
        (m) => m.NotFoundRoutingModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
