import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ThrottleClickDirective } from '../../directives/throttle-click.directive';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-user-pop-over',
  templateUrl: './user-pop-over.component.html',
  styleUrls: ['./user-pop-over.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, ThrottleClickDirective],
})
export class UserPopOverComponent implements OnInit {
  private user: User | null;
  private isClicked: boolean = false;

  constructor(
    private router: Router,
    private toast: ToastNotificationService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
      } else {
        this.navigateToLogin();
      }
    });
  }

  checkRoles: Function = (): boolean => {
    return this.user &&
      this.user.roles.length > 1 &&
      this.router.url.includes('admin-comm-control-panel')
      ? true
      : false;
  };

  onSwitchRole: Function = (): void => {
    if (this.checkRoles()) {
      this.router.navigate(['/admin-comm-control-panel/welcome']);
    }
  };

  navigateToLogin: Function = () => {
    this.router.navigate(['/login'], { replaceUrl: true });
  };

  onLogout: Function = (): void => {
    this.isClicked = true;
    this.apiService.userLogout(this.user.email).subscribe(
      (res) => {
        this.apiService.logout();
        this.navigateToLogin();
        this.toast.presentToastSuccess('Logged out successfully.');
      },

      (error) => {
        if (error !== 401) {
          this.toast.presentToastError('An error has occured.');
        }
      }
    );
  };
}
