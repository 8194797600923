import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RecipientNavbarComponent } from '../../components/recipient-navbar/recipient-navbar.component';
import { FsLogoBannerComponent } from '../../components/fs-logo-banner/fs-logo-banner.component';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.page.html',
  styleUrls: ['./recipient.page.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RecipientNavbarComponent],
})
export class RecipientPage implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  hasRoute: Function = (route: string): boolean => {
    return this.router.url.includes(route);
  };
}
