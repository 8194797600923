import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [],
  imports: [CommonModule, IonicModule],
  exports: [CommonModule, IonicModule],
})
export class SharedModule {}
