import { Component, OnInit, HostListener } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { Router, RouterModule } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { Channel, SubscribedChannel } from 'src/app/core/models/channel';
import { CommonModule } from '@angular/common';
import { RecipientChannelItemComponent } from '../../components/recipient-channel-item/recipient-channel-item.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ApiService } from 'src/app/core/services/api.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FsLogoBannerComponent } from '../../components/fs-logo-banner/fs-logo-banner.component';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, RecipientChannelItemComponent, FsLogoBannerComponent],
})
export class DiscoverComponent implements OnInit {
  private user: User | null;

  private alerted: boolean = false;

  rendered: boolean = false;
  tierThreeChannels: Channel[] = [];
  subscribedTierThreeChannels: Channel[] = [];

  constructor(
    public alertController: AlertController,
    private apiService: ApiService,
    private loader: LoaderService,
    private router: Router,
    private toast: ToastNotificationService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
        this.getTierThreeChannels();
      }
    });
  }

  ionViewWillLeave() {
    this.rendered = true;
    this.loader.dismiss();
  }

  ionViewDidEnter() {
    if (this.rendered) {
      this.getTierThreeChannels();
    }
  }

  getTierThreeChannels: Function = () => {
    this.loader.presentControlPanelLoader();
    this.apiService.getReceiverChannels(this.user._id, 'THREE').subscribe(
      (res) => {
        this.subscribedTierThreeChannels = res.results['THREE'];
        if (this.subscribedTierThreeChannels) {
          this.subscribedTierThreeChannels =
            this.subscribedTierThreeChannels.filter((channel) => {
              return channel.channels.isSubscribed === true;
            });

          this.subscribedTierThreeChannels =
            this.subscribedTierThreeChannels.map((channel) => {
              return { ...channel, expand: false };
            });
        }
        this.loader.dismiss();
      },

      (error) => {
        this.loader.dismiss();
        if (error !== 401) {
          this.toast.presentToastError(
            'An error has occured, please try again later.'
          );
        }
      }
    );
  };

  isNotEmpty: Function = (): boolean => {
    return (
      this.subscribedTierThreeChannels &&
      this.subscribedTierThreeChannels.length > 0
    );
  };

  unsubscribeChannel: Function = (targetChannel: Channel): void => {
    if (targetChannel) {
      this.apiService.deleteReceiverChannels(targetChannel._id).subscribe(
        (res) => {
          this.subscribedTierThreeChannels =
            this.subscribedTierThreeChannels.filter((channel) => {
              return channel.channelId._id !== targetChannel.channelId.email;
            });
          this.toast.presentToastSuccess(
            `Successfully unsubscribed from ${targetChannel.channelId.name}.`
          );
        },

        (error) => {
          if (error !== 401) {
            this.toast.presentToastError(
              'An error has occured, please try again later.'
            );
          }
        }
      );
    } else {
      this.toast.presentToastError(
        'An error has occured, please try again later.'
      );
    }
  };

  presentAlertUnsubscribe: Function = async (targetChannel: Channel) => {
    // prevent duplicate pop up
    if (!this.alerted) {
      this.alerted = true;
      const alert = await this.alertController.create({
        cssClass: 'my-alert-css-receiver-discover',
        message: `Do you want to unsubscribe from <strong>${targetChannel.channelId.name}</strong>?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'cancel-btn',
            handler: () => {},
          },
          {
            text: 'Unsubscribe',
            handler: () => {
              if (targetChannel) {
                this.unsubscribeChannel(targetChannel);
              }
            },
          },
        ],
      });

      await alert.present();
      await alert.onDidDismiss();
      this.alerted = false;
    }
  };
}
