import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserPopOverComponent } from 'src/app/shared/components/user-pop-over/user-pop-over.component';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  
  alertStatus: boolean = false;
  
  constructor(private popoverController: PopoverController) {}

  async presentPopover(ev: any) {
    if(!this.alertStatus){
      this.alertStatus = true;
      const popover = await this.popoverController.create({
        component: UserPopOverComponent,
        event: ev,
        translucent: true,
        dismissOnSelect: true,
        showBackdrop: false,
        reference: 'trigger',
        side: 'bottom',
        alignment: 'end',
        
      });
    await popover.present();
    await popover.onDidDismiss();
    this.alertStatus = false;
    }
  }
}
