import { Component, OnInit } from '@angular/core';
import { Channel } from 'src/app/core/models/channel';
import { CommonModule } from '@angular/common';
import { RecipientChannelItemComponent } from '../../components/recipient-channel-item/recipient-channel-item.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ApiService } from 'src/app/core/services/api.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { User } from 'src/app/core/models/user';
import { take } from 'rxjs/operators';
import { FsLogoBannerComponent } from '../../components/fs-logo-banner/fs-logo-banner.component';

@Component({
  selector: 'app-essential',
  templateUrl: './essential.component.html',
  styleUrls: ['./essential.component.scss'],
  standalone: true,
  imports: [CommonModule, RecipientChannelItemComponent, FsLogoBannerComponent],
})
export class EssentialComponent implements OnInit {
  user: User = null;
  tierOneChannels: Channel[];
  rendered = false;

  constructor(
    private toast: ToastNotificationService,
    private apiService: ApiService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
        this.getTierOneChannels();
      } else {
        this.toast.presentToastError(
          'An error has occurred, please refresh the page and try again.'
        );
      }
    });
  }

  ionViewWillLeave() {
    this.rendered = true;
    this.loader.dismiss();
  }

  ionViewDidEnter() {
    if (this.rendered) {
      this.getTierOneChannels();
    }
  }

  getTierOneChannels: Function = (): void => {
    this.loader.presentControlPanelLoader();
    this.apiService.getReceiverChannels(this.user._id, 'ONE').subscribe(
      (res) => {
        this.tierOneChannels = res.results['ONE'] as Channel[];
        this.tierOneChannels = this.tierOneChannels?.map((channel) => {
          return { ...channel, expand: false };
        });
        this.loader.dismiss();
      },

      (error) => {
        if (error !== 401) {
          this.toast.presentToastError(
            'An error has occured, please try again later.'
          );
        }
        this.loader.dismiss();
      }
    );
  };
}
